<template>
   <div class="section-pricing section-final">
      <div class="container container-pricing">
     <div id="pricing"></div>
         <div class="creatividad-home">
            <div class="content-rueda1">
               <img id="rueda1" src="../../assets/images/globe.webp" alt="planes presupuesto web"
                  data-aos="zoom-in"
                  data-aos-duration="2350" 
                  data-aos-delay="100"
               >
            </div>
         </div>


         <v-row class="pricinghead">
            <v-col lg="12">
               <h2 class="main">{{$t('pricing')}}</h2>
               <h3> {{$t('chooseplan')}}</h3>
               <p v-html="$t('pricingtext')">
                  Cada proyecto es único y lo primero siempre es definir bien las características que necesita. <br>
                  Tenemos <span class="number">3</span> planes para ofrecerte la mejor solución a tu medida.
               </p>
            </v-col>
         </v-row>
         <v-row>
            <v-col lg="4" cols="12" class="col-pad-20">
               <div class="pricing pricing-basic"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-delay="100"
               >
                  <h3> BASIC </h3>
                  <ul>
                     <li
                        v-for="(concept, i ) in concepts.slice(0, 3)"
                        :key="i"
                        >
                        {{concept.name}}
                     </li>
                  </ul>
                  <p class="text-center">
                     <v-btn
                        class="btn-pricing"
                        elevation="3"
                        large
                        rounded
                        outlined
                        :to="`/${$i18n.locale}/contact`"
                     >
                     {{ $t('pricingbutton') }}
                     </v-btn>
                  </p>
               </div>
            </v-col>
            <v-col lg="4" cols="12" class="col-pad-20">
               <div class="pricing pricing-standard"
                  data-aos="fade-up"
                  data-aos-duration="950"
                  data-aos-delay="200" 
                  >
               
                  <h3> STANDARD </h3>
                  <ul>
                     <li
                     v-for="(concept, i ) in concepts.slice(0, 5)"
                     :key="i"
                     >{{concept.name}}</li>
                  </ul>
                  <p class="text-center">
                     <v-btn
                        class="btn-pricing"
                        elevation="3"
                        large
                        rounded
                        outlined
                        :to="`/${$i18n.locale}/contact`"
                     >
                     {{ $t('pricingbutton') }}
                     </v-btn>
                  </p>
               </div>
            </v-col>
            <v-col lg="4" cols="12" class="col-pad-20">
               <div class="pricing pricing-premium"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                  data-aos-delay="400" 
               >
                  <h3> PREMIUM </h3>
                  <ul>
                     <li
                        v-for="(concept, i ) in concepts.slice(0, 10)"
                        :key="i"
                         v-html="concept.name">

                     </li>
                  </ul>
                  <p class="text-center">
                     <v-btn
                        class="btn-pricing"
                        elevation="3"
                        large
                        rounded
                        outlined
                        :to="`/${$i18n.locale}/contact`"
                     >
                     {{ $t('pricingbutton') }}
                     </v-btn>
                  </p>
               </div>
            </v-col>
         </v-row>
      </div>
   </div>
</template>


<script>
   export default {
      name: 'Pricing',
      props: {
      },
      mounted() {
         function scrollRotate() {/* 
            let image = document.getElementById("rueda1");
            image.style.transform = "rotate(" + window.pageYOffset/2 + "deg)"; */
   /*          let image2 = document.getElementById("rueda2");
            image2.style.transform = "rotate(" + window.pageYOffset/34 + "deg) "; */
   /*          let image3 = document.getElementById("rueda3");
            image3.style.transform = "rotate(" + window.pageYOffset/24 + "deg) "; */
   /*          let image4 = document.getElementByClassName("base-globe");
            image4.style.transform = "rotate(" + window.pageYOffset/24 + "deg) "; */
      /*      image.style.transform = "translateY(" + window.pageYOffset/52 + "%)";  rotate(" + window.pageYOffset/24 + "deg) */
         }
         window.onscroll = function () {
            scrollRotate();
         };
      },
      computed: {
         location: () => window.location,
         concepts() {
            return [
               { 
                  name: this.$i18n.t('pricingplan.websiteaudit'),
                  icon: "mdi-google-analytics"
               },
               { 
                  
                  name: this.$i18n.t('pricingplan.development'),
                  icon: "mdi-flower-tulip-outline"
               },
               { 
                  name: this.$i18n.t('pricingplan.design'),
                  icon: "mdi-dialpad"
               },
               {  
                  name: this.$i18n.t('pricingplan.multilanguage'),
                  icon: "mdi-head-cog-outline"
               },
               { 
                  name: this.$i18n.t('pricingplan.content'),
                  icon: "mdi-iframe"
               },
               { 
                  name: this.$i18n.t('pricingplan.ecommerce'),
                  icon: "mdi-cellphone-link"
               },
               { 
                  name: this.$i18n.t('pricingplan.hosting'),
                  icon: "mdi-dialpad"
               },
               {  
                  name: this.$i18n.t('pricingplan.database'),
                  icon: "mdi-head-cog-outline"
               },
               { 
                  name: this.$i18n.t('pricingplan.CMS'),
                  icon: "mdi-iframe"
               },
               { 
                  name: this.$i18n.t('pricingplan.functionality'),
                  icon: "mdi-cellphone-link"
               },
            ]
         }
      },
   }
</script>

<style lang="scss">

.col-pad-20 {
   padding: 20px !important;
}

.container-pricing{
   overflow: hidden;
   position: relative;
}

.pricing {
   background-color:"#454545";
   box-shadow: 5px 5px 30px #333333;
   border-radius: 24px;
   background: white;
   text-align: center;
   margin-bottom: 20px;
   position: relative;
   overflow: hidden !important;
   z-index: 1;

   h2 {
      color: white;
   }

   h3 {
      font-size: 1.2em;
      color: #414141;
   }

   &-basic {
      
      &:after {
         content: "";
         z-index: 0;
         height: 215px;
         opacity: 0.5;
         background: #f29e325e;
      }
   }
   &-standard {
      &:after {
         content: "";
         height: 255px;
         background: #a432f25e;
      }
   }
   &-premium {

      &:after {
         content: "";
         right: 0;
         z-index: 0;
         height: 285px;
         background: #2ceac65e;
      }
   }
   &:after {
      content: "";
      top: 300px;
      left: 50%;
      z-index: 0;
      transform: translateX(-58%) rotate(106deg);
      width: 1000px;
      opacity: 0.5;
      position: absolute;
   }
   ul {
      padding: 0 0 40px;
   }
   & ul li {
      display: block;
      text-align: center;
      padding: 8px;
      font-size: .8em;
   }

   .btn-pricing {
      margin-bottom: 30px;
      z-index:1;
   }
}

</style>
